import { useProject } from "@/contexts";
import {
  FirebaseTrackingEventEnum,
  FirebaseTrackingTypeEnum,
} from "@/consts/FirebaseTrackingEnum";
import { logEvent } from "@/services/firebase/firebase";

import { useTracking } from "react-tracking";

export type FirebaseTrackerType = {
  fn?: (props: any) => void;
  event: FirebaseTrackingEventEnum;
  type: FirebaseTrackingTypeEnum;
  option?: Record<string, any>;
};

export const useFirebasePageTracking = ({
  page,
  defaultOption,
}: {
  page?: string;
  defaultOption?: { [key: string]: any };
}) => {
  const { project } = useProject();
  const track = useTracking(page ? { page } : undefined, {
    dispatch: (
      data: { type: FirebaseTrackingTypeEnum } & { [key: string]: unknown }
    ) => {
      logEvent(data.type, data);
      // console.log(`firebase logging ${data.type}: ${JSON.stringify(data)}`);
    },
  });

  const firebaseTracker = ({
    event,
    fn,
    type,
    option,
  }: FirebaseTrackerType) => (props?: any) => {
    track.trackEvent({
      ...(defaultOption ?? {}),
      ...(option ?? {}),
      event,
      type,
      activeProject: project?.name,
    });
    fn && fn(props);
  };

  return { ...track, firebaseTracker };
};
