/**
 * @TrackingEnum
 * enum convenction
 * FEATURE _ _ EVENT _ _ (SUB_EVENT) _ _ INDICATOR
 * single underscore for split within one word, double underscore for split between words
 */
export enum FirebaseTrackingTypeEnum {
  FirebaseAuthSignInGoogle = "FIREBASE_AUTH__SIGNIN_GOOGLE",
  FirebaseAuthSignInEmailLinkSendEmail = "FIREBASE_AUTH__SIGNIN_EMAIL_LINK__SEND_EMAIL",
  FirebaseAuthSignInEmailLinkValidateEmail = "FIREBASE_AUTH__SIGNIN_EMAIL_LINK__VALIDATE_EMAIL",
  FirebaseAuthSignInEmailPassword = "FIREBASE_AUTH__SIGNIN_EMAIL_PASSWORD",
  FirebaseAuthSignOut = "FIREBASE_AUTH__SIGNOUT",
  NavigationProjectSelection = "NAVIGATION__PROJECT_SELECTION",
  SidebarItemClick = "SIDEBAR__ITEM_CLICK",
  ButtonCopyToClipboard = "BUTTON__COPY_TO_CLIPBOARD",
  PageNotFoundBackToHome = "PAGE_NOT_FOUND_BACK_TO_HOME",
  TableFilterBoolean = "TABLE__FILTER__BOOLEAN",
  TableFilterDate = "TABLE__FILTER__DATE",
  TableFilterEnum = "TABLE__FILTER__ENUM",
  TableFilterMultiEnum = "TABLE__FILTER__MULTI_ENUM",
  TableFilterNumber = "TABLE__FILTER__NUMBER",
  TableFilterString = "TABLE__FILTER__STRING",
  TableFilterSearchBox = "TABLE__FILTER__SEARCHBOX",
  TableFilterSortHeader = "TABLE__FILTER__SORT_HEADER",
  TableFilterClear = "TABLE__FILTER_CLEAR",
  TableActionInfiniteScroll = "TABLE__ACTION__INFINITE_SCROLL",
}

export enum FirebaseTrackingEventEnum {
  Redirect = "REDIRECT",
  OnClick = "ONCLICK",
  OnSubmit = "ONSUBMIT",
  OnScroll = "ONSCROLL",
}
