import { useProject } from "@/contexts";
import { ArrowRightIcon } from "@heroicons/react/solid";
import Image from "next/image";
import Link from "next/link";
import { useFirebasePageTracking } from "@/hooks/useFirebasePageTracking";
import { useRouter } from "next/router";
import {
  FirebaseTrackingEventEnum,
  FirebaseTrackingTypeEnum,
} from "@/consts/FirebaseTrackingEnum";

export default function Custom404() {
  const { project } = useProject();
  const { route } = useRouter();
  const { firebaseTracker } = useFirebasePageTracking({
    page: route,
  });

  const backPath = project?.slug ? `/${project.slug}/dashboard` : "/";

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-primary-2">
      <div className="flex space-x-4">
        <div className="hidden md:block">
          <Image
            src="/images/notFound.png"
            height={412}
            width={363}
            objectFit="contain"
          />
        </div>
        <div className="flex flex-col justify-center space-y-2">
          <h1 className="text-9xl leading-none font-normal text-center text-primary">
            404
          </h1>
          <span className="text-5xl leading-none font-extrabold tracking-tight text-center text-gray-900">
            Page not found.
          </span>
          <span className="text-base leading-6 font-normal text-gray-500 text-center">
            {"Sorry, we couldn't find the page you're looking for."}
          </span>
          <Link href={backPath}>
            <a
              className="mt-6 text-center text-base leading-6 font-medium text-primary"
              onClick={firebaseTracker({
                event: FirebaseTrackingEventEnum.Redirect,
                type: FirebaseTrackingTypeEnum.PageNotFoundBackToHome,
                option: {
                  params: {
                    path: backPath,
                  },
                },
              })}
            >
              Go back home <ArrowRightIcon className="inline w-4 h-4" />
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
